import useUserDiscount from "@components/_hooks/useUserDiscount";
import Typography from "@mui/material/Typography"
import dynamic from "next/dynamic"
import { useSelector } from "react-redux"
import { count, getBrand, isDefined, vatPricing } from "../../config/helpers"
// import ProductPriceTypography from "../Product/ProductPriceTypography"

const ProductPriceTypography = dynamic(() => import("../ProductComponent/ProductPriceTypography"), {
    ssr: true,
});
const PriceInfoTag = dynamic(() => import("../ProductComponent/PriceInfoTag"), {
    ssr: true,
});


const ProductPriceContainer = (props) => {
    const {
        on_offer, poa_price, price_breaks, product_price, new_price, prcie, all_in_price
    } = props;

    const {hasProductDiscounts} = useUserDiscount();
    
    const discount_value = hasProductDiscounts();
    const VATFlag = useSelector((state) => state.globalReducer.VATFlag);
    const tax = useSelector((state) => state.globalReducer.tax);
    const pricing_model = useSelector((state) => state.globalReducer.pricing_model);

    const priceTag = () => {
        if(on_offer == 'yes'){
            if(poa_price == 'no'){
                if(price_breaks && count(price_breaks) > 0){
                    return <ProductPriceTypography on_offer={on_offer || discount_value} type="has_offer" price={vatPricing(VATFlag, tax, isDefined(new_price) ? new_price : product_price, all_in_price, discount_value, pricing_model)} />
                }
                else {
                    return <ProductPriceTypography on_offer={on_offer || discount_value} type="no_offer" price={vatPricing(VATFlag, tax, isDefined(new_price) ? new_price : product_price, all_in_price, discount_value, pricing_model)} />
                }
            }
            else {
                <span>POA</span>
            }
        }
        else {
            if(poa_price == 'no'){
                if(isDefined(price_breaks) && count(price_breaks) > 0){
                    return <ProductPriceTypography on_offer={discount_value} type="has_offer" price={vatPricing(VATFlag, tax, product_price, all_in_price, discount_value, pricing_model)} />
                }
                else {
                    return <ProductPriceTypography on_offer={discount_value} type="no_offer" price={vatPricing(VATFlag, tax, product_price, all_in_price, discount_value, pricing_model)} />
                }
            }
            else {
                <span>POA</span>
            }
        }
    }
    return (
        <Typography className="mb-2 d-flex align-items-center">
            {priceTag()}
            {pricing_model == 1 && (
                <>
                    &nbsp;<PriceInfoTag title="Price includes delivery, build, takedown &amp; collection"/>
                </>
            )}
            {/* Long term hire section */}
        </Typography>
    )
}

export default ProductPriceContainer